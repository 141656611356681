export default {
  title: "هیدسیم: خرید شماره مجازی و دریافت پیامک آنلاین",
  description:
    "دریافت پیامک آنلاین با شماره تلفن مجازی و ثبت نام به صورت ناشناس در هر سرویس",
  og: {
    title: "هیدسیم: شماره مجازی و دریافت پیامک آنلاین",
    description:
      "دریافت پیامک آنلاین با شماره تلفن مجازی و ثبت نام به صورت ناشناس در هر سرویس",
    site_name: "هیدسیم",
    buyVirtualNumbersFor: "خرید شماره مجازی برای {{name}}",
  },
  login: "ورود",
  register: "ثبت نام",
  profile: "پروفایل",
  balance: "موجودی",
  dangerZone: "منطقه خطر",
  deleteAccount: "حذف اکانت",
  backToHome: "بازگشت به خانه",
  topUp: "شارژ",
  language: "زبان",
  currency: "واحد پول",
  currencies: "واحدهای پول",
  settings: "تنظیمات",
  myReceipts: "فاکتورهای من",
  support: "پشتیبانی",
  chatOnline: "چت آنلاین",
  downloadIosApp: "دانلود اپلیکیشن iOS",
  downloadAndroidApp: "دانلود اپلیکیشن اندروید",
  receipts: "فاکتورها",
  invoices: "رسیدها",
  logout: "خروج",
  buyVirtualNumbersFor: "خرید شماره مجازی برای {{name}}",
  connecting: "در حال اتصال",
  connected: "متصل شد",
  disconnected: "اتصال قطع شده",
  retry: "تلاش مجدد",
  credit: "اعتبار",
  credits: "اعتبارها",
  coins: "سکه",
  coin_one: "{{count}} سکه",
  coin_other: "{{count}} سکه",
  services: "سرویس‌ها",
  operations: "عملیات",
  pending: "در انتظار",
  refunded: "مرجوع شده",
  completed: "تکمیل شده",
  searchServices: "جستجو در سرویس‌ها",
  searchCountryFor: "جستجو در کشور برای {{name}}",
  default: "پیش‌فرض",
  priceHighToLow: "قیمت بالا به پایین",
  priceLowToHigh: "قیمت پایین به بالا",
  qualityHighToLow: "کیفیت زیاد به کم",
  qualityLowToHigh: "کیفیت کم به زیاد",
  nothingIsHereYet: "هنوز چیزی وجود ندارد",
  okay: "باشه",
  myWallet: "کیف پول من",
  copied: "کپی شد",
  copy: "کپی",
  numbersAvailable: "شماره موجود",
  noNumbersAvailable: "شماره‌ای موجود نیست",
  floating: "شناور",
  automatic: "خودکار",
  findingCountry: "در حال یافتن کشور",
  letHidSimFind: "بذار هیدسیم پیدا کنه",
  refund: "مرجوع",
  sessions: "نشست‌ها",
  activeSessions: "نشست‌های فعال",
  myDevices: "دستگاه‌های من",
  devices: "دستگاه‌ها",
  revoke: "حذف",
  installAndroidApp: "نصب اپلیکیشن اندروید",
  installIosApp: "نصب اپلیکیشن iOS",
  discard: "بیخیال",
  pagination: {
    previous: "قبلی",
    next: "بعدی",
  },
  readMore: "ادامه مطلب",
  showMore: "نمایش بیشتر",
  didNotReceiveCode: "کدی دریافت نکردید؟",
  providers: {
    p1: "اپراتور مخابراتی یک",
    p2: "اپراتور مخابراتی دو",
    p3: "اپراتور مخابراتی سه",
  },
  grades: {
    unknown: "ناشناخته",
    poor: "ضعیف",
    moderate: "متوسط",
    standard: "استاندارد",
  },
  qualityTheBetterTheHigher:
    "کیفیت: بالاتر بودن احتمال دریافت کد برای یک سرویس و شماره به معنی بالاتر بودن کیفیت آن است.",
  theCountOfAvailableNumbersOperatorIndicated:
    "تعداد شماره‌های موجود نمایش داده شده توسط اپراتور است. این تعداد ممکن است دقیق نباشد. ما توصیه می‌کنیم سرویس را استفاده کنید و ببینیم آیا کار می‌کند یا خیر. اگر کار نمی‌کند، مبلغ به صورت خودکار به موجودی شما بازگردانده می‌شود.",
  errors: {
    emailAddressAlreadyTaken: "ایمیل قبلاً استفاده شده است",
    "number.base": "لطفاً یک عدد وارد کنید",
    "number.empty": "این فیلد نمی‌تواند خالی باشد",
    "any.required": "این فیلد الزامی است",
    "any.only": "مقدار وارد شده مجاز نیست",
    "string.base": "لطفاً این فیلد را پر کنید",
    "string.empty": "این فیلد نمی‌تواند خالی باشد",
    "0": "اتصال اینترنت وجود ندارد",
    "0_message": "مشکلی در اتصال به اینترنت وجود داشت، لطفاً دوباره تلاش کنید",
    "401": "عدم اعتبار",
    "401_message":
      "شما مجوز دسترسی به این بخش را ندارید، لطفاً با مدیر سایت تماس بگیرید",
    "500": "خطای اتصال",
    "500_message":
      "مشکلی در اتصال به اینترنت وجود دارد، لطفاً دوباره تلاش کنید",
    "404": "پیدا نشد",
    "404_message":
      "ما نتوانستیم آنچه را که به دنبال آن بودید پیدا کنیم، آیا گم شده‌اید؟ برای رفتن به خانه دکمه زیر را کلیک کنید",
    "409": "توکن منقضی شده",
    "409_message":
      "توکن اخیر شما منقضی شده است، لطفاً صفحه را مجدداً بارگذاری کنید و با معامله ادامه دهید",
    "429": "تعداد درخواست‌ها بیش از حد مجاز است",
    "429_message":
      "تعداد درخواست‌ها بیش از حد مجاز است، لطفاً بعد از {{seconds}} ثانیه دوباره تلاش کنید",
  },
  faq: "سوالات متداول",
  home: {
    title: "هیدسیم، خرید شماره مجازی سرویس‌های آنلاین",
    description:
      "تصور کنید قصد ساخت حساب جهت استفاده از شبکه های اجتماعی همانند اینستاگرام، توییتر، فیسبوک و سایتها و نرم افزارهای مختلف هستید، اما جهت ساخت و تکمیل ثبت نام نیاز به وارد کردن شماره موبایل و تأیید پیامکی هستید! مخصوصا زمانی که امکان استفاده از شماره شخصی شما برایتان مقدور نیست و یا تمایل به استفاده از آن ندارید و یا برای استفاده های شخصی و تجاری نیاز به ساخت تعداد زیادی حساب کاربری دارد؛ پنل شماره مجازی هیدسیم این امکان را به شما میدهد که به صورت نامحدود از بیش از 160 کشور مختلف جهان از جمله کشورهای اروپائی و آسیائی و... اقدام به دریافت شماره با هزینه بسیار کم کنید. شماره هایی که امکان دریافت پیامک حاوی کد فعالسازی جهت ساخت اکانت در هر سرویسی را دارا هستند.",
    benefits: {
      instantDelivery: "تحویل فوری",
      secureAndAnonymous: "امن و ناشناس",
      onlyPayForReceivedCodes: "پرداخت به ازای کد",
      visaAndCreditCardAccepted: "پرداخت از طریق کارت بانکی",
      cryptoAccepted: "پرداخت کریپتو",
    },
    faq: {
      q1: "چگونه میتونم شماره خودمو بگیرم؟",
      a1: "شما می‌توانید با استفاده از ارزهای رمزنگاری شده یا کارت‌های بانکی، موجودی خود را شارژ کنید. پس از شارژ موجودی، سرویس و کشور مورد نظر خود را انتخاب کنید و سپس با استفاده از اعتبار خود، شماره‌های مجازی را خریداری کنید.",

      q2: "چه اتفاقی می‌افتد اگر کد دریافت نکنم؟",
      a2: "اگر کدی را در شماره خود دریافت نکردید، ممکن است دلایل متعددی وجود داشته باشد، مانند مشکلات شبکه یا تأخیر در ارسال سرویس مورد نظر. در این صورت، می‌توانید فعالسازی را لغو کرده و با یک شماره جدید دوباره تلاش کنید؛ مبلغ به صورت خودکار به موجودی شما بازگردانده می‌شود. استفاده از یک VPN یا پراکسی از کشور شماره ممکن است بهبود موفقیت دریافت را به همراه داشته باشد.",

      q3: "چقدر شماره‌ها برای من در دسترس هستند؟",
      a3: "هنگامی که یک شماره را خریداری می‌کنید، برای یک بار استفاده موجود است و به مدت 15 دقیقه متعلق به شما می‌شود. در طول این دوره، شماره را در سرویس مورد نظر وارد کنید. HidSim به دنبال کد ورودی است و آن را در برگه فعالسازی در داخل اپلیکیشن ما نمایش می‌دهد. پس از دریافت کد، شماره دیگر فعال نیست.",

      q4: "چرا شماره من توسط سرویس مسدود شده است؟",
      a4: "شماره شما ممکن است به دلیل الگوهای استفاده، مشکلات آدرس IP یا علامت گذاری به عنوان رباتیک مسدود شده باشد. برای جلوگیری از این موضوع، از یک نام و تصویر پروفایل واقعی استفاده کنید، در گفتگوهای واقعی شرکت کنید، از خرید شماره‌ها به سرعت خودداری کنید و در نظر بگیرید از یک VPN استفاده کنید که با کشور سرویس مطابقت داشته باشد.",

      q5: "چرا نمی‌توانم کدی در سرویس‌های تلگرام دریافت کنم؟",
      a5: "برای اولین ورود به تلگرام، از لپ‌تاپ یا تبلت استفاده نکنید. از یک تلفن همراه برای ایجاد حساب کاربری خود استفاده کنید. پس از تکمیل اولین ثبت‌نام خود، می‌توانید حساب خود را به هر دستگاه دیگری که ترجیح می‌دهید منتقل کنید.",

      q6: "آیا می‌توانم برای یک شماره بیش از یک بار کد دریافت کنم؟",
      a6: "خیر، امکان دریافت کد برای همان شماره بیش از یک بار وجود ندارد. هر شماره مجازی فقط برای یک بار استفاده است و پس از دریافت کد، غیرفعال می‌شود.",

      qOther: "آیا سوال دیگری دارید؟",
      aOther:
        'با تیم پشتیبانی بسیار عالی ما در <0 href="https://t.me/HidSimsupport" target="_blank" style="color: #6B96BD;">تلگرام</0> چت کنید و در عرض چند دقیقه پاسخ بگیرید، 24/7. <1 href="https://t.me/HidSimsupport" target="_blank" style="color: #6B96BD;">با پشتیبانی در تلگرام تماس بگیرید</1>',
    },
  },
  navbar: {
    myAccount: "اکانت من",
    startNow: "شروع کن",
    login: "ورود",
    services: "سرویس‌ها",
    profile: "پروفایل",
    myNumbers: "شماره‌های من",
  },
  operation: {
    creating: "در حال ایجاد",
    pending: "در انتظار",
    completed: "تکمیل شده",
    no_number_refunded: "مرجوع شده به دلیل عدم وجود شماره",
    system_refunded: "مرجوعی خودکار سیستم",
    user_refunded: "مرجوع شده توسط شما",
    waitingForMessage: "در انتظار پیام",
  },
  receipt: {
    purchase: "خرید شماره",
    refund_with_revoke: "مرجوع شده با ریوک",
    refund_with_fraud_revoke: "مرجوع شده به دلیل تقلب",
    refund_with_user_action: "مرجوع شده توسط کاربر",
    refund_with_system_action: "مرجوع شده توسط سیستم",
    system_freeze: "بلوکه شده‌",
    system_unfreeze: "آزاد شده",
    system_fee: "فی",
  },
  invoice: {
    paid: "پرداخت شده",
    paid_over: "پرداخت بیش از حد",
    wrong_amount: "مبلغ نادرست",
    process: "در حال پردازش",
    confirm_check: "تأیید چک",
    wrong_amount_waiting: "مبلغ نادرست در انتظار",
    check: "چک",
    fail: "ناموفق",
    failed: "ناموفق",
    cancel: "لغو",
    canceled: "لغو شده",
    system_fail: "خطای سیستم",
    refund_process: "پرداخت مجدد",
    refund_fail: "ناموفق در مرجوع",
    refund_paid: "استرداد موفقیت آمیز",
  },
  auth: {
    login: {
      "mobile.number": "شماره موبایل",
      password: "رمز عبور",
      "email.address": "آدرس ایمیل",
      invalid: "ایمیل یا رمز عبور نامعتبر است",
    },
    register: {
      "mobile.number": "شماره موبایل",
      "email.address": "آدرس ایمیل",
      password: {
        hash: "رمز عبور",
        confirmation: "تأیید رمز عبور",
      },
      profile: {
        name: {
          first: "نام",
          last: "نام خانوادگی",
        },
      },
    },
    alreadyHaveAccount: "قبلاً ثبت نام کرده‌اید؟",
    notHaveAccount: "هنوز ثبت نام نکرده‌اید؟",
    forgotYourPassword: "رمز عبور خود را فراموش کرده‌اید؟",
    registerNow: "همین حالا ثبت نام کنید",
    loginToHidSim: "ورود به هیدسیم",
    registerToHidSim: "ثبت نام در هیدسیم",
    enterYourCredentials: "اطلاعات خود را وارد کنید",
    backToHome: "بازگشت به خانه",
    byRegisteringYouAgreeToOurTermsOfService: `با ثبت نام شما با <2>قوانین استفاده</2> و <6>محرمانگی</6> سرویس موافقت کرده‌اید.`,
  },
  landing: {
    nav: {},
  },
  payment: {
    paid: {
      title: "پرداخت موفق بود",
      description:
        "<0>مبلغ </0><1>{{amount}}</1><2>{{currency}}</2><3> با موفقیت پرداخت شد.</3>",
    },
    failed: {
      title: "پرداخت ناموفق بود",
      description:
        "<0>پرداخت شما</0><1>{{status}}</1><2> بود، لطفا مجدد سعی کنید.</2>",
    },
  },
  modals: {
    revokeConfirmation: {
      title: "آیا مطمئنید؟",
      browser: "مرورگر",
      os: "سیستم عامل",
      country: "کشور",
      ip: "آی‌پی",
      discard: "بیخیال",
      pleaseWait: "لطفاً صبر کنید",
      revoke: "حذف",
    },
    addToHome: {
      widget: {
        title: "وب‌اپلیشکیشن هیدسیم",
        description: "استفاده آسان‌تر با نصب وب‌اپلیکیشن",
        add: "نصب",
      },
      title: "هیدسیم را به صفحه اصلی اضافه کنید",
      ios: {
        one: "۱. با <b>مرورگر سفاری</b> روی جعبه با فلش خروجی کلیک کنید، مشابه <img />",
        two: "۲. سپس روی <span key='0'>Add To Home Screen</span> کلیک کنید.",
        three:
          "۳. لطفاً چند ثانیه صبر کنید تا آیکون و اطلاعات ظاهر شود و سپس روی دکمه <span>Add</span> کلیک کنید.",
      },
      android: {
        one: "۱. روی <b>۳ نقطه</b> در بالای صفحه، کنار نوار آدرس در <b>کروم</b> کلیک کنید.",
        two: "۲. روی <b>Add To Home Screen</b> کلیک کنید.",
        three: "۳. روی گزینه <b>نصب</b> کلیک کنید.",
      },
    },
    charge: {
      title: "خرید سکه",
      payWithCrypto: "پرداخت با کریپتو",
      payWithCreditCard: "پرداخت با ویزا و مستر",
      payWithIrLocalGateway: "پرداخت از طریق درگاه بانکی",
      payWithIrLocalGatewaySubtitle:
        "حساب خود را به‌راحتی با کارت‌های شتاب شارژ کنید.",
      payWithCryptoSubtitle:
        "با استفاده از رمزارزهایی مانند بیت‌کوین، تتر و سایر ارزهای دیجیتال، حساب خود را شارژ کنید.",
      payWithCreditCardSubtitle:
        "برای پرداخت با کردیت‌کارت یا دبیت‌کارت، لطفاً از اپلیکیشن‌ هیدسیم در گوگل‌پلی و اپ‌استور استفاده کنید. لینک‌های دانلود در صفحه پروفایل موجود هستند. در صورت بروز هرگونه مشکل در پرداخت، با ما تماس بگیرید.",
      mostPopular: "پرطرفدار",
      otherPackages: "بسته‌های دیگر",
      submitBtn:
        "<0>خرید </0><1>{{name}}</1><2> با </2><3><0>{{symbol}}</0><1>{{price}}</1></3>",
      notAcceptable:
        "این روش پرداخت با توجه به واحد پولی شما در دسترس نیست، لطفا ابتدا واحد پولی خود را تغییر دهید و مجددا تلاش کنید.",
      submitting: "لطفا صبر کنید",
      selectPackage: "ابتدا بسته را انتخاب کنید",
      selectGateway: "ابتدا درگاه را انتخاب کنید",
    },
    orderConfirmation: {
      orderNumber: "سفارش شماره",
      refundPolicy:
        "در صورت عدم دریافت پیامک تا 20 دقیقه، هزینه به موجودی بازگشت داده می‌شود.",
      notEnoughCredits:
        "در حال حاضر اعتبار کافی ندارید. برای استفاده از این شماره به {{amount}} اعتبار بیشتر نیاز دارید.",
      orderFor: "<0>خرید با</0><1>{{amount}}</1><2>سکه</2>",
      pleaseWait: "لطفاً صبر کنید",
      addCredits: "افزودن اعتبار",
    },
    autoOrderConfirmation: {
      p1: "اپراتور مخابراتی ۱",
      p2: "اپراتور مخابراتی ۲",
      refundPolicy: `هیدسیم به صورت خودکار به دنبال کشور مناسب با شرایط انتخابی شما خواهد گشت، در طول این زمان لزومی به باز بودن صفحه اپلیکیشن نبوده و شما میتوانید برنامه رو ترک کرده و مجدد برگردید.`,
      noProductFoundForThisRange:
        "محصولی برای این قیمت پیدا نشد، لطفا محدوده قیمتی خود را عوض کنید.",
      upperLimit: "حداکثر قیمت",
      lowerLimit: "حداقل قیمت",
      note: "۱۰ درصد فی برای خریدهای خودکار اعمال خواهد شد",
    },
    cancelOrderConfirmation: {
      refundNumber: "مرجوعی شماره",
      areYouSure:"آیا مطمئنید که می خواهید این شماره را مرجوع کنید؟ اعتبار بلافاصله به کیف پول شما برمی گردد.",
      refund: "مرجوع کن",
      pleaseWait: "لطفاً صبر کنید",
      discard: "بستن",
    },
    deleteUserConfirmation: {
      deleteAccount: "حذف اکانت",
      areYouSure:
        "آیا مطمئنید که می‌خواهید اکانت خود را حذف کنید؟ این عملیات غیرقابل بازگشت است.",
      delete: "حذف",
      pleaseWait: "لطفاً صبر کنید",
      discard: "بستن",
    },
    operationWhy: {
      whyNumberIsRefunded: {
        no_number_refunded: "چرا شماره‌ای پیدا نشد؟",
        system_refunded: "چرا هزینه برگشت داده شد؟",
        timeout_system_refunded: "چرا هزینه برگشت داده شد؟",
        user_refunded: "مرجوع شده توسط شما",
      },
      no_number_refunded:
        "اعداد موجودی برای هر کشور، بر اساس موجودی هیدسیم در اپراتورهای مخابراتی خارج از کشور است. اما برخی مواقع، با وجود داشتن موجودی، شماره‌ای از سمت اپراتورهای مخابراتی برای هیدسیم فعال نمی‌شود و شما با اخطار «شماره ای موجود نیست» مواجه می شوید. این مشکل به‌خاطر بروز اختلالات در اپراتورهای مخابراتی خارج از کشور است و هیدسیم نقشی در آن ندارد. شما میتوانید کشور دیگه را انتخاب کرده و یا از گزینه خودکار استفاده کنید..",
      system_refunded:
        "به دلیل خطای سیستم، شماره ای که خریداری کردید فعال نشد. سیستم به طور خودکار مبلغی را که پرداخت کرده اید مرجوع می کند. بعد از چند دقیقه یا چند ساعت می توانید دوباره امتحان کنید.",
      timeout_system_refunded:
        "پس از ۱۵ تا ۲۰ دقیقه عدم فعالیت شما و یا عدم ارسال کد پیامکی به شماره، سیستم به طور خودکار مبلغ خرید را به کیف پول شما مرجوع کرد.",
      user_refunded: "این خرید توسط شما مرجوع شده.",
    },
  },
};
